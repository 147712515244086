<template>
	<!-- <header :class="navBarFixed == true 'headersss' : 'header'" v-if="showheader" :style="{marginTop:marage + 'px'}"> -->
	<header :class="navBarFixed ? 'headersss' : 'header'" v-if="showheader" :style="{marginTop:marage + 'px'}">
		<div class="">
			<!-- 点击弹出 -->
			<div class="head_box">
				<ul>
					<li :class="selectIndex == 1 ? 'textColor' : ''" @click="gopages(1,'/home')">首页</li>
					<li :class="selectIndex == 2 ? 'textColor' : ''" @click="gopages(2,'/productcenter')">产品中心</li>
					<li :class="selectIndex == 3 ? 'textColor' : ''" @click="gopages(3,'/teacher')">师资课库</li>
					<li :class="selectIndex == 4 ? 'textColor' : ''" @click="gopages(4,'/partner')">城市合伙人</li>
					<li :class="selectIndex == 5 ? 'textColor' : ''" @click="gopages(5,'/news')">新闻动态</li>
					<li :class="selectIndex == 6 ? 'textColor' : ''" @click="gopages(6,'/my')">关于我们</li>
				</ul>
			</div>
		</div>
	</header>
</template>

<script>
	import Vue from "vue";
	import {
		Cell,
		CellGroup,
		Popup,
	} from "vant";
	Vue.use(Popup);
	Vue.use(Cell);
	Vue.use(CellGroup);

	export default {
		data() {
			return {
				// Enter,
				// Tutor,
				show: false,
				navBarFixed: false,
				showEt: false,
				showTu: false,
				// iconLG: require("../assets/img/homeimg/LOGO.png"),
				iconQi: require("../assets/img/homeimg/ic_qi.png"),
				iconRen: require("../assets/img/homeimg/ic_ren2.png"),
				scrollTop: 0,
				showheader: true,
				marg:'190',
			};
		},
		props:{
			marage:{
				type:Number,
				default: 0
			},
			selectIndex:{
				type:Number,
				default: 1
			}
		},
		created() {
			if (this.isMobile() == null) {
				window.location.href = 'http://www.qikevip.com'
			}
			this.backTop()
		},
		mounted() {
			// 事件监听滚动条
			window.addEventListener("scroll", this.watchScroll);
		},
		watch: {
			$route() {
				this.backTop();
				this.show = false;
			},
		},
		methods: {
			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag;
			},
			gopages(index,url){
				// this.selectIndex = index
				this.$router.push(url)
				this.show = false;
				// if(this.$route.path == url){
				// 	this.$router.go(0)
				// }
			},
			// 取消企业入住
			companyClose() {
				this.showEt = false;
			},
			// 取消导师入住
			tutorClose() {
				this.showTu = false;
			},
			backTop() {
				const that = this;
				let timer = setInterval(() => {
					let ispeed = Math.floor(-that.scrollTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop =
						that.scrollTop + ispeed;
					if (that.scrollTop === 0) {
						clearInterval(timer);
					}
				}, 1);
			},
			getContainer() {
				return document.querySelector("html");
			},
			showPopup() {
				this.show = !this.show;
			},
			showEnterprise() {
				this.showEt = !this.showEt;
				this.show = false;
			},
			showTutor() {
				this.showTu = !this.showTu;
				this.show = false;
			},
			watchScroll() {
				var scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				//  当滚动超过 50 时，实现吸顶效果
				if(scrollTop > this.marage){
					this.navBarFixed = true
				}else{
					this.navBarFixed = false
				}
				// console.log(scrollTop)
				// if (scrollTop > 1) {
				// 	this.navBarFixed = true;
				// } else {
				// 	this.navBarFixed = false;
				// }
				// if (scrollTop > 250){
					// this.showheader = true;
				// }else{
				// 	this.showheader = false;
				// }
			},
			download() {
				var androidURL = "https://web.qikevip.com/qike-app-release.apk";
				var browser = {
					versions: (function() {
						var u = navigator.userAgent;
						return {
							android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1,
							iPhone: u.indexOf("iPhone") > -1,
							iPad: u.indexOf("iPad") > -1,
							iPod: u.indexOf("iPod") > -1,
						};
					})(),
					language: (navigator.browserLanguage || navigator.language).toLowerCase(),
				};
				if (browser.versions.iPhone || browser.versions.iPad || browser.versions.iPod) {
					//如果是ios系统，直接跳转至appstore该应用首页，传递参数为该应用在appstroe的id号
					window.location.href =
						"https://apps.apple.com/cn/app/企课网-实效企业大学/id1277152899";
				} else if (browser.versions.android) {
					window.location.href = androidURL;
				}
			},
			logo() {
				this.$router.push({
					name: "home"
				});
			},

		},
	};
</script>
<style lang="stylus" scoped>
	.header {
		position: absolute
		z-index: 999
		background #fff 
		width 100% 
		height: 50px
		.head_box {
			widows: 100% 
			height: 50px 
			overflow: hidden; 
			ul {
				display: flex;
				height: 50px;
				background: #ffffff
				li {
					width: 150px 
					text-align: center 
					line-height: 50px
				}
			}
		}
	}
	
	.headersss {
		position: fixed;
		top: 0;
		z-index: 999
		background #fff 
		width 100% 
		height: 50px
		margin-top: 0 !important
		box-shadow: 1px 1px 5px #888888;
		.head_box {
			widows: 100% 
			height: 50px 
			overflow: hidden; 
			ul {
				display: flex;
				height: 50px;
				background: #ffffff
				li {
					width: 150px 
					text-align: center 
					line-height: 50px
				}
			}
		}
	}
	
	
	.textColor{
		color: red !important
	}
</style>
